<template>
  <div>
    <div class="title">
      <div class="login">设置密码</div>
      <div class="register" @click="$router.push({ path: 'login' })">
        直接登录
      </div>
    </div>
    <div class="content">
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="pho"
          center
          clearable
          placeholder="请输入手机号"
          label-width="250px"
        >
        </van-field>
      </div>
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="yzm"
          center
          clearable
          placeholder="请输入验证码"
          label-width="250px"
        >
          <template #right-icon>
            <div v-show="codeShow" class="VerificationCode" @click="LoginFrom">
              获取验证码
            </div>
            <div v-show="!codeShow" class="VerificationCode">
              {{ yzmTitle }}
            </div>
          </template>
        </van-field>
      </div>
      <!-- ----------------------------------------------------------- -->
      <div class="code">
        <van-field
          ref="abc"
          v-show="pswShow"
          type="password"
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="psw"
          center
          clearable
          placeholder="设置您的新密码"
          label-width="250px"
        >
          <template #right-icon>
            <van-icon @click="pswShowClick()" name="closed-eye" size="18px" />
          </template>
        </van-field>
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="psw"
          v-show="!pswShow"
          center
          clearable
          placeholder="设置您的新密码"
          label-width="250px"
        >
          <template #right-icon>
            <van-icon @click="pswShowClick()" name="eye-o" size="18px" />
          </template>
        </van-field>
      </div>
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="newPsw"
          center
          clearable
          placeholder="再次输入您的密码"
          label-width="250px"
        >
        </van-field>
      </div>
      <div class="submit" @click="submit">确定</div>
    </div>
  </div>
</template>

<script>
import { LoginDate, LoginPwd } from "@/api/login";
let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
export default {
  data() {
    return {
      psw: "",
      newPsw: "",
      show: false,
      pho: "",
      yzm: "",
      codeShow: true,
      yzmTitle: "",
      pswShow: true
    };
  },
  methods: {
    submit() {
      // alert("111");
      if (!phonereg.test(this.pho) || !this.pho) {
        this.$toast("手机号格式不正确或未填手机号");
        return;
      }
      if (!this.yzm) {
        this.$toast("请输入短信验证码！");
        return;
      }
      if (!this.psw || !this.newPsw) {
        this.$toast("密码不能为空");
        return;
      }
      if (this.psw != this.newPsw) {
        this.$toast("两次密码输入不一致");
      }

      let params = {
        mobile: this.pho,
        password: this.psw,
        ChePassword: this.newPsw,
        code: this.yzm
      };
      LoginPwd(params).then(res => {
        console.log(res);
        console.log(params);
        if (res.data.code == 200) {
          this.$router.push("/login");
          this.$toast("修改密码成功，请重新登录");
        }
      });
    },
    pswShowClick() {
      this.pswShow = !this.pswShow;
    },
    LoginFrom() {
      if (!phonereg.test(this.pho)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.pho) {
        this.$toast("请输入手机号码");
        return;
      }
      let params = {
        mobile: this.pho
      };
      LoginDate(params).then(res => {
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShow = true;
            } else {
              this.codeShow = false;
              this.yzmTitle = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast("验证码获取成功");
        } else {
          this.$toast(res.data.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 31px;
  font-size: 33px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  padding-top: 88px;
  text-align: center;
  .login {
    // text-align: center;
    font-size: 33px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  .register {
    position: absolute;
    right: 63px;
    top: 95px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.content {
  padding-top: 120px;
  margin: 0 65px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .iphoneNumber {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    .inputPhoneNumber {
      height: 100%;
      margin-left: 40px;
    }
  }
  .Lonin {
    margin-top: 65px !important;
  }
  .code,
  .Lonin {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    margin-top: 52px;
    .inputCode {
      height: 100%;
      margin-left: 40px;
    }
    .VerificationCode {
      width: 174px;
      height: 51px;
      border: 1px solid #d2d2d2;
      border-radius: 26px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
  .submit {
    width: 100%;
    height: 90px;
    background: linear-gradient(-30deg, #323234, #464646);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    margin-top: 52px;

    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
  .VerificationCode {
    width: 100px;
    height: 100px;
  }
}
</style>
